.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .contact-info {
    margin-bottom: 2rem;
  }
  
  .contact-info p {
    margin: 0.5rem 0;
  }
  
  .contact-picture {
    border-radius: 0%;
    width: 200px;
    height: 200px;
    margin-top: 2rem;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .social-links a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #0056b3;
  }
  
  .contact-form {
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  