.project-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .project-title {
    font-size: 1.5rem;
    margin: 0 0 8px 0;
  }
  
  .project-description {
    font-size: 1rem;
    margin: 0 0 16px 0;
  }
  
  .project-link {
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .project-link:hover {
    text-decoration: underline;
  }
  
  .project-technologies {
    margin-top: 16px;
  }
  
  .tech-bubble {
    display: inline-block;
    background-color: #f1f1f1;
    border-radius: 16px;
    padding: 8px 12px;
    margin: 4px;
    font-size: 0.9rem;
    color: #333;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  