.footer {
    text-align: center;
    padding: 1rem;
    background-color: #f1f1f1;
  }
  
  .footer-links a {
    margin: 0 10px;
    color: inherit;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  